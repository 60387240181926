@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';

@cms-filters-form-cls: ~'@{ra-prefix}-cms-filters-form';
@cms-filters-form-input-cls: ~'@{cms-filters-form-cls}-input';
@cms-filters-form-submit-btn-cls: ~'@{cms-filters-form-cls}-submit-btn';

:export {
  cmsFiltersFormCls: @cms-filters-form-cls;
  cmsFiltersFormInputCls: @cms-filters-form-input-cls;
  cmsFiltersFormSubmitBtnCls: @cms-filters-form-submit-btn-cls;
}

.@{cms-filters-form-cls} {
  .@{cms-filters-form-input-cls} {
    .ant-select-selection-placeholder {
      color: @ra-color-text;
      font-weight: @ra-font-weight-bold;
    }
  }

  .ra-btn.ant-btn.@{cms-filters-form-submit-btn-cls} {
    padding-left: 10px;
    padding-right: 10px;
  }
}
